<template>
    <div class="page" >
        <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg" alt="close-button" class="closeButton" @click="closeDialog()">
        <h1 style="margin-bottom: 20px; color: #1467BF; font-size: x-large;" >
            PRESCRIPTION DETAILS
        </h1>
        <div id="presentingSymptoms" style="display: flex; justify-content: space-between; justify-content: center;" >
            <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                Presenting Symptoms: 
            </p>
            <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.presentingSymptoms"
                outlined
                style="text-align: center;"
            ></v-textarea>
            </div>
            <div id="div2" style="display: flex; justify-content: space-between;" >
            <div>
                <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                Past Medical History: 
                </p>
                <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.pastMedicalHistory"
                outlined
                style="text-align: center;"
                ></v-textarea>
            </div>
            <div>
                <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                Past Surgical History: 
                </p>
                <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.pastSurgicalHistory"
                outlined
                style="text-align: center;"
                ></v-textarea>
            </div>
            <div>
                <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                Family History: 
                </p>
                <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.familyHistory"
                outlined
                style="text-align: center;"
                ></v-textarea>
            </div>
            </div>
            <div id="div3" style="display: flex; justify-content: space-between;" >
            <div>
                <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                Regular taking / Current medicine: 
                </p>
                <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.regularAndCurrentMedicines"
                outlined
                style="text-align: center;"
                ></v-textarea>
            </div>
            <div>
                <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                Known Drug Allergies: 
                </p>
                <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.knownDrugAllergies"
                outlined
                style="text-align: center;"
                ></v-textarea>
            </div>
            <div>
                <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                Vaccination History: 
                </p>
                <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.vaccinationHistory"
                outlined
                style="text-align: center;"
                ></v-textarea>
            </div>
            </div>
            <div id="diagnosisDiv" style="display: flex; justify-content: space-between;" >
            <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                Diagnosis: 
            </p>
            <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.diagnosis"
                outlined
                style="text-align: center;"
            ></v-textarea>
            </div>
            <div id="treatmentDiv" style="margin-bottom: 20px;" >
            <table style=" width: 100%; border: 1px solid #1467BF; border-radius: 8px;" class="prescriptionTableClass">
                <tr align="left" style="padding: 15px;">
                <td style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</td>
                <td style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</td>
                <td style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</td>
                <td style="font-size:16px; line-height: 20px; padding: 5px;">Duration</td>
                </tr>
                <tr style="padding: 15px; font-weight: 300;" v-for="(item,index) in prescriptionDetails.treatment" :key=index>
                <td style="padding:5px;">
                    <v-text-field :disabled="!prescription_fill_flag" class="textfield_class" outlined
                    v-model="item.medicine"/>
                </td>
                <td style="padding:5px;">
                    <v-text-field :disabled="!prescription_fill_flag" class="textfield_class" outlined
                    v-model="item.dosage"/>
                </td>
                <td style="padding:5px;">
                    <v-select :disabled="!prescription_fill_flag" outlined background-color="white" :items="frequency_list" v-model="item.frequency"></v-select>
                </td>
                <td style="padding:5px;">
                    <v-text-field :disabled="!prescription_fill_flag" class="textfield_class" outlined
                    v-model="item.duration"/>
                </td>
                <td class="addRemoveButton" v-if="addDeleteEnable" >
                    <img v-if="index == prescriptionDetails.treatment.length-1" 
                    src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" 
                    @click="addTreatmentInfo"
                    >
                    <img v-else-if="index < prescriptionDetails.treatment.length" 
                    src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" 
                    @click="removeTreatmentInfo(item.medicine,item.dosage,item.frequency,item.duration)"
                    >
                </td>
                </tr>
            </table>
            </div>
            <div id="suggestionsDiv" style="display: flex; justify-content: space-between;">
            <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                Suggestions: 
            </p>
            <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.suggestions"
                outlined
                style="text-align: center;"
            ></v-textarea>
            </div>
            <div id="investigationDiv" style="display: flex; justify-content: space-between;">
            <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                Investigations Needed: 
            </p>
            <v-textarea
                :disabled="disabledFlag"
                rows="1"
                v-model="prescriptionDetails.investigationsNeeded"
                outlined
                style="text-align: center;"
            ></v-textarea>
            </div>
            <div id="comeAfterDiv" style="width: 100%; display: flex; justify-content: left;">
            <p class="prescriptionHeadings" 
                style="width: 20%; margin-top: 20px; margin-right: 10px; text-align: left;" >
                To Come After: 
            </p>
            <div style="width: 200px" >
                <v-text-field
                    :disabled="disabledFlag"
                    type="date"
                    v-model="prescriptionDetails.toComeAfter"
                    outlined
                    style="text-align: center;"
                ></v-text-field>
            </div>
            </div>
            <div id="referDiv" style="width: 100%; display: flex; justify-content: left;">
            <p class="prescriptionHeadings" 
                style="width: 20%; margin-top: 20px; margin-right: 10px; text-align: left;" >
                Refer To: 
            </p>
            <div style="width: 200px" >
                <v-select
                    :disabled="disabledFlag"
                    type="date"
                    v-model="prescriptionDetails.referTo"
                    :items="specialization_list"
                    item-text="specialization_name" item-value="specialization_key" 
                    outlined
                    return-object
                    style="text-align: center;"
                ></v-select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrescriptionDetailsComponent',
    props: {
        prescriptionDetails: {
            type: Object,
            required: true
        },
        frequency_list: {
            type: Array,
            required: true
        },
        specialization_list: {
            type: Array,
            required: true
        },
        addDeleteEnable: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            disabledFlag: true,
            prescription_fill_flag: false
        }
    },
    created() {
    },
    mounted() {
        console.log('Component prescriptionDetails: ', this.prescriptionDetails);
    },
    methods: {
        addTreatmentInfo () {
          this.prescriptionDetails.treatment.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
        },
        removeTreatmentInfo (medicine,dosage,frequency,duration) {
          this.prescriptionDetails.treatment.forEach(function (item, index, object) {
            if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration)
            {
              object.splice(index, 1);
            }
          });
        },
        closeDialog() {
            this.$emit('closeViewPrescription', false);
        }
    }
}
</script>

<style>
.page{
    padding: 40px;
}
.prescriptionHeadings {
  font-size: large; font-weight: 500;
}
.addRemoveButton {
  cursor: pointer;
}
.closeButton {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}
</style>