<template>
    <div class="gradientBG" style="width: 100%;" >
      <!-- Top Bar Segment -->
      <img
        class="logoClass"
        src="https://s3iconimages.mymedicine.com.mm/HBA_MyMedicineLogo.svg"
      />
  
      <div class="pageBody" >
        <!-- Video Call Segment -->
        <div  style="width: 30%; min-width: 300px; min-height: 100vh; position: relative;"  v-if="!this.callDisconnected">
          <div style="padding-top: 5%;" >
            <div id="remote-media-div-remote" class="remoteParticipant"></div>
          </div>
          <div id="remote-media-div-local" ></div>
  
          <!-- CALL CONTROLS SEGMENT -->
          <div
            class="callControlsSegment"
            style="display:flex; flex-direction: row;justify-content: space-evenly; margin : 0px 20px 0px 0px !important "
            v-if="callConnected"
            >
            <div style="margin:2px" v-if="deviceIDList.length > 1">
              <v-btn
                elevation="8"
                :disabled="isCameraSwitched"
                v-if="videoBtnFlag"
                style=" background: #FFFFFF;border-radius: 8px; color:white;position: absolute; top: 620px; margin-bottom: 30px;"
                @click="switchCamera(activeRoom)"
                icon
                ><img
                  src="https://s3iconimages.mymedicine.com.mm/switchCamera.svg"/>
              </v-btn>
            </div>
            <div style="margin:2px">
              <v-btn
                elevation="8"
                v-if="videoBtnFlag"
                style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; top: 620px; margin-bottom: 30px;"
                @click="turnVideoOff"
                icon
                ><img
                  src="https://s3iconimages.mymedicine.com.mm/CameraOn.svg"/>
              </v-btn>
              <v-btn
                elevation="8"
                v-else
                style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; top: 620px; margin-bottom: 30px;"
                @click="turnVideoOn"
                icon
                ><img
                  src="https://s3iconimages.mymedicine.com.mm/CameraOff.svg"
              /></v-btn>
            </div>
            <div style="margin:2px">
              <v-btn
                elevation="8"
                v-if="audioBtnFlag"
                style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; top: 620px; margin-bottom: 30px;"
                @click="turnAudioOff"
                id="muteAudio"
                icon
                >
                <img src="https://s3iconimages.mymedicine.com.mm/micOn.svg"/>
              </v-btn>
              <v-btn
                elevation="8"
                v-else
                style="  background: #FFFFFF;border-radius: 8px; color:white;position: absolute; top: 620px; margin-bottom: 30px;"
                @click="turnAudioOn"
                icon
                >
                <img src="https://s3iconimages.mymedicine.com.mm/micOff.svg"/>
              </v-btn>
            </div>
            <div style="margin:2px">
              <v-btn
                elevation="8"
                id="disconnectBtn"
                style="background: #EB5757; border-radius: 8px; position: absolute; top: 620px; margin-bottom: 30px;"
                @click="endTwilioVideoCall"
                icon
                >
                <img src="https://s3iconimages.mymedicine.com.mm/leaveCall.svg"/>
              </v-btn>
            </div>
          </div>
        </div>
        <div  style="width: 30%; min-width: 300px; min-height: 100vh; display: flex; align-items: center; justify-content: center; font-size: 2rem; font-weight: 800; color: red;"  v-else>
          Disconnected
        </div>

  
        <!-- Prescription Segment -->
        <div style=" width: 100%; margin-top: 20px; margin-right: 30px; overflow: scroll; height: 85vh;" >
          <div class="patientDetails" style="margin-left: 10px;" >
            <h1 style="text-align: left; font-size: x-large; margin-bottom: 10px;" >Patient Details</h1>
            <v-card>
              <v-card-title>Booking ID: {{ appointmentDetails.appointment_details.appointment_reference_number }}</v-card-title>
              
              <v-divider></v-divider>
  
              <div style="padding: 20px; font-size: large; color: #828282;" >
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px;" >
                  <p>Patient Name: {{ appointmentDetails.patient_data.customer_name }}</p>
                  <p>Date: {{ appointmentDetails.patient_data.date_of_appointment }}</p>
                  <p>Time Slot: {{ appointmentDetails.patient_data.time_slot }}</p>
                </div>
  
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px;" >
                  <p>Gender: {{ appointmentDetails.patient_data.gender }}</p>
                  <p>Birth Year: {{ appointmentDetails.patient_data.birthYear }}</p>
                </div>
                
                <div style="display: flex; flex-direction: column; text-align: left; margin-bottom: 20px;" >
                  <p style="font-weight: 600;" >Description: </p>
                  <div>
                    <!-- <p>lorem ipsum....</p> -->
                  </div>
                </div>
              </div>
            </v-card>
          </div>
  
          <div class="prescriptionDetails" style="">
            <!-- <h1 style="text-align: left; font-size: x-large; margin-top: 20px; margin-left: 10px;" >Prescription Details</h1> -->
            <v-tabs
              v-model="tab"
              fixed-tabs
              background-color="#1976d2"
              style="margin-top: 2%; margin-bottom: 1%; margin-left: 10px; border-radius: 8px !important;"
              >
              
              <v-tab style="font-size: medium;" v-for="item in items" :key="item.tab" active-class="activeClass" dark>
                {{ item.tab }}
              </v-tab>
              
              <v-tab-item style="padding: 30px; border-radius: 8px !important;" >
                <div id="presentingSymptoms" style="display: flex; justify-content: space-between; justify-content: center;" >
                  <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                    Presenting Symptoms: 
                  </p>
                  <v-textarea
                    rows="1"
                    v-model="prescriptionDetails.presentingSymptoms"
                    outlined
                    style="text-align: center;"
                  ></v-textarea>
                </div>
                <div id="div2" style="display: flex; justify-content: space-between;" >
                  <div>
                    <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                      Past Medical History: 
                    </p>
                    <v-textarea
                      rows="1"
                      v-model="prescriptionDetails.pastMedicalHistory"
                      outlined
                      style="text-align: center;"
                    ></v-textarea>
                  </div>
                  <div>
                    <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                      Past Surgical History: 
                    </p>
                    <v-textarea
                      rows="1"
                      v-model="prescriptionDetails.pastSurgicalHistory"
                      outlined
                      style="text-align: center;"
                    ></v-textarea>
                  </div>
                  <div>
                    <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                      Family History: 
                    </p>
                    <v-textarea
                      rows="1"
                      v-model="prescriptionDetails.familyHistory"
                      outlined
                      style="text-align: center;"
                    ></v-textarea>
                  </div>
                </div>
                <div id="div3" style="display: flex; justify-content: space-between;" >
                  <div>
                    <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                      Regular taking / Current medicine: 
                    </p>
                    <v-textarea
                      rows="1"
                      v-model="prescriptionDetails.regularAndCurrentMedicines"
                      outlined
                      style="text-align: center;"
                    ></v-textarea>
                  </div>
                  <div>
                    <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                      Known Drug Allergies: 
                    </p>
                    <v-textarea
                      rows="1"
                      v-model="prescriptionDetails.knownDrugAllergies"
                      outlined
                      style="text-align: center;"
                    ></v-textarea>
                  </div>
                  <div>
                    <p class="prescriptionHeadings" style="margin-top: 10px; margin-right: 10px; margin-bottom: 5px;" >
                      Vaccination History: 
                    </p>
                    <v-textarea
                      rows="1"
                      v-model="prescriptionDetails.vaccinationHistory"
                      outlined
                      style="text-align: center;"
                    ></v-textarea>
                  </div>
                </div>
                <div id="diagnosisDiv" style="display: flex; justify-content: space-between;" >
                  <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                    Diagnosis: 
                  </p>
                  <v-textarea
                    rows="1"
                    v-model="prescriptionDetails.diagnosis"
                    outlined
                    style="text-align: center;"
                  ></v-textarea>
                </div>
                <div id="treatmentDiv" style="margin-bottom: 20px;" >
                  <table style=" width: 100%; border: 1px solid #1467BF; border-radius: 8px;" class="prescriptionTableClass">
                    <tr align="left" style="padding: 15px;">
                      <td style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</td>
                      <td style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</td>
                      <td style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</td>
                      <td style="font-size:16px; line-height: 20px; padding: 5px;">Duration</td>
                    </tr>
                    <tr style="padding: 15px; font-weight: 300;" v-for="(item,index) in prescriptionDetails.treatment" :key=index>
                      <td style="padding:5px;">
                        <v-text-field :disabled="!prescription_fill_flag" class="textfield_class" outlined
                        v-model="item.medicine"/>
                      </td>
                      <td style="padding:5px;">
                        <v-text-field :disabled="!prescription_fill_flag" class="textfield_class" outlined
                        v-model="item.dosage"/>
                      </td>
                      <td style="padding:5px;">
                        <v-select :disabled="!prescription_fill_flag" outlined background-color="white" :items="frequency_list" v-model="item.frequency"></v-select>
                      </td>
                      <td style="padding:5px;">
                        <v-text-field :disabled="!prescription_fill_flag" class="textfield_class" outlined
                        v-model="item.duration"/>
                      </td>
                      <td class="addRemoveButton">
                        <img v-if="index == prescriptionDetails.treatment.length-1" 
                          src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg" style="margin-top:-50%;" 
                          @click="addTreatmentInfo"
                        >
                        <img v-else-if="index < prescriptionDetails.treatment.length" 
                          src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" 
                          @click="removeTreatmentInfo(item.medicine,item.dosage,item.frequency,item.duration)"
                        >
                      </td>
                    </tr>
                  </table>
                </div>
                <div id="suggestionsDiv" style="display: flex; justify-content: space-between;">
                  <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                    Suggestions: 
                  </p>
                  <v-textarea
                    rows="1"
                    v-model="prescriptionDetails.suggestions"
                    outlined
                    style="text-align: center;"
                  ></v-textarea>
                </div>
                <div id="investigationDiv" style="display: flex; justify-content: space-between;">
                  <p class="prescriptionHeadings" style="margin-top: 20px; margin-right: 10px;" >
                    Investigations Needed: 
                  </p>
                  <v-textarea
                    rows="1"
                    v-model="prescriptionDetails.investigationsNeeded"
                    outlined
                    style="text-align: center;"
                  ></v-textarea>
                </div>
                <div id="comeAfterDiv" style="width: 100%; display: flex; justify-content: left;">
                  <p class="prescriptionHeadings" 
                    style="width: 20%; margin-top: 20px; margin-right: 10px; text-align: left;" >
                    To Come After: 
                  </p>
                  <div style="width: 200px" >
                    <v-text-field
                      type="date"
                      v-model="prescriptionDetails.toComeAfter"
                      outlined
                      style="text-align: center;"
                    ></v-text-field>
                  </div>
                </div>
                <div id="referDiv" style="width: 100%; display: flex; justify-content: left;">
                  <p class="prescriptionHeadings" 
                    style="width: 20%; margin-top: 20px; margin-right: 10px; text-align: left;" >
                    Refer To: 
                  </p>
                  <div style="width: 200px" >
                    <v-select
                      type="date"
                      v-model="prescriptionDetails.referTo"
                      :items="specialization_list"
                      item-text="specialization_name" item-value="specialization_key" 
                      outlined
                      return-object
                      style="text-align: center;"
                    ></v-select>
                  </div>
                </div>
  
                <v-btn
                  color="rgb(25 118 210)"
                  style="color: white; margin-top: 20px;"
                  @click="savePrescriptionAndExit()"
                >
                  Save Prescription & Exit
                </v-btn>
              </v-tab-item>
  
              <v-tab-item style="padding: 30px; border-radius: 8px !important;" >
                <!-- <p>Prescription History Not Available Right Now</p> -->
  
                <v-card v-for="p in prescriptionHistory" 
                  style="padding: 20px; width: 400px;"
                  >
                  <p style="margin-bottom: 4px;" >
                    <span style="font-weight: 500;" >Doctor Name:</span> 
                    {{ p.doctor_name }}</p>
                  <p style="margin-bottom: 4px;" >
                    <span style="font-weight: 500;" >Doctor Specialization:</span> 
                    {{ p.specialization }}</p>
                  <p style="margin-bottom: 0px;" >
                    <span style="font-weight: 500;" >Date:</span> 
                    {{ p.date_of_appointment }}</p>
                  <v-btn
                    color="rgb(25 118 210)"
                    style="color: white; margin-top: 20px;"
                    @click="openPrescription(p)"
                  >
                    View Prescription
                  </v-btn>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </div>
  
      <v-dialog v-model="viewPrescription" persistent width="900">
        <v-card>
          <PrescriptionDetailsComponent 
            :prescriptionDetails="viewPrescriptionObject"
            :frequency_list="frequency_list"
            :specialization_list="specialization_list"
            :addDeleteEnable="false"
            @closeViewPrescription="closeViewPrescription"
            >
          </PrescriptionDetailsComponent>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
  import TopBar from './TopBar.vue'
  import PrescriptionPageComponent from '../Doctor/videoCallTwilioDoc.vue'
  import PrescriptionDetailsComponent from "./PrescriptionDetailsComponent.vue";
  import * as Twilio from "twilio-video";
  
  export default  {
      name: 'VideoCallPageCPI',
      components: {
        TopBar,
        PrescriptionPageComponent,
        PrescriptionDetailsComponent
      },
      data() {
          return {
              token: '',
              callDisconnected: false,
              videoConstraint: {},
              currentFacingMode: "user",
              customer_name: "",
              ConnectTrack: null,
              twilioRoomName: '',
              activeRoom: null,
              remoteParticipant: null,
              container: null,
              callConnected: false,
              deviceIDList: [],
              isCameraSwitched: false,
              videoBtnFlag: true,
              audioBtnFlag: true,
              waitTimer: true,
              bookingID: '',
              currentUser: '',
              items: [
                { tab: "Write Prescription", content: "Tab 1 Content" },
                { tab: "Prescription History", content: "Tab 2 Content" },
              ],
              appointmentDetails: {},
              prescription_fill_flag: true,
              prescriptionDetails: {
                presentingSymptoms: '',
                pastMedicalHistory: '',
                pastSurgicalHistory: '',
                familyHistory: '',
                regularAndCurrentMedicines: '',
                knownDrugAllergies: '',
                vaccinationHistory: '',
                diagnosis: '',
                treatment: [
                  {
                    medicine: '',
                    dosage: '',
                    frequency: '',
                    duration: '',
                  }
                ],
                suggestions: '',
                investigationsNeeded: '',
                toComeAfter: '',
                referTo: '',
              },
              prescriptionHistory: [],
              others: "",
              specialization_list: [],
              selected_specialization: "",
              frequency_list: ["OD","CM","HS","BD","TDS","QID","PRN"],
              viewPrescriptionObject: {},
              viewPrescription: false
          };
      },
      async mounted() {
        try {
          this.currentUser = this.$cookies.get("doctorToken");
          if (!this.currentUser) {
            this.$router.push({
              name: "DoctorLogin",
            });
          }
            this.token = this.$route.params.token;
            this.bookingID = this.$route.params.appointmentID;
            this.container = document.getElementById("video-container");
            this.startRoom();
        } catch (error) {
            console.error(error);
        }
        this.getSpecializationList();
        this.getCPIAppointmentPrescriptions();
      },
      methods: {
          async joinVideoRoom(roomName, token) {
            try {
              let tracks;
              try {
                tracks = await Twilio.createLocalTracks({
                    audio: true,
                    video: {
                      facingMode: "user"
                    },
                });
              } catch (error) {
                alert('Not able to connect media');
                throw error;
              } 
  
              let room = await Twilio.connect(token, { name: roomName, tracks });
              this.activeRoom = room;
              console.log(room, "prakers");
              console.log(this.activeRoom.name);
  
              // this.activeRoom.disconnect();
              this.callConnected = true;
              this.renderLocalParticpantMediaInTheRoom(room);
              this.handleAlreadyConnectedRemoteParticpant(room);
              this.renderNewRemotePartipantOnJoining(room);
              this.handleDisconnectedParticipant(room);
              return room;
  
            } catch (error) {
              console.log(error);
            }
          },
          async startRoom() {
              const room = await this.joinVideoRoom(this.twilioRoomName, this.token);
              await this.joinVideoCallLogEntry('DOCTOR', this.bookingID);
              this.activeRoom = room;
          },
           removeElementByID(elementID) {
            let element = document.getElementById(elementID);
            while(element) {
              element.remove(); // Removes the element from the DOM
              console.log(`Element with ID "${elementID}" removed.`);
              element = document.getElementById(elementID);
            }
          },
         
          handleAlreadyConnectedRemoteParticpant(room) {
            room.participants.forEach(participant => {
              participant.tracks.forEach(publication => {
                  if (publication.track) {
                    const mainDiv = document.createElement("div");
                    mainDiv.id = participant.identity;
  
                    console.log('handleAlreadyConnectedRemoteParticpant() mainDiv.id: ', mainDiv.id);
  
                    mainDiv.appendChild(publication.track.attach());
                    document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                  }
              });
  
              participant.on('trackSubscribed', track => {
                const mainDiv = document.createElement("div");
                mainDiv.id = participant.identity;
                mainDiv.appendChild(track.attach());
                document.getElementById('remote-media-div-remote').appendChild(mainDiv);
              });
            });
          },
          renderLocalParticpantMediaInTheRoom(room) {
            room.localParticipant.tracks.forEach(publication => {
                if (publication.track) {
                    const mainDiv = document.createElement("div");
                    mainDiv.id = room.localParticipant.identity;
  
                    console.log('renderLocalParticpantMediaInTheRoom() mainDiv.id: ', mainDiv.id);
  
                    mainDiv.appendChild(publication.track.attach());
                    document.getElementById('remote-media-div-local').appendChild(mainDiv);
                }
            });
            navigator.mediaDevices.enumerateDevices().then((cams) => {
                cams.forEach(mediaDevice => {
                    if (mediaDevice.kind === 'videoinput') {
                        this.deviceIDList.push({ name: mediaDevice.label, deviceID: mediaDevice.deviceId });
                    }
                });
            });
          },
          renderNewRemotePartipantOnJoining(room) {
            room.on('participantConnected', participant => {
                console.log(`A remote Participant connected: ${participant}`);
                console.log(`Participant "${participant.identity}" connected`);
                participant.tracks.forEach(publication => {
                    if (publication.isSubscribed) {
                        const track = publication.track;
                        const mainDiv = document.createElement("div");
                        mainDiv.id = participant.identity;
  
                        console.log('renderNewRemotePartipantOnJoining() mainDiv.id: ', mainDiv.id);
  
                        mainDiv.appendChild(track.attach());
                        document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                    }
                });
  
                participant.on('trackSubscribed', track => {
                    const mainDiv = document.createElement("div");
                    mainDiv.id = participant.identity;
                    mainDiv.appendChild(track.attach());
                    document.getElementById('remote-media-div-remote').appendChild(mainDiv);
                });
            });
          },
          handleDisconnectedParticipant(room) {
            room.on('participantDisconnected', participant => {
              console.log(`A remote Participant disconnected: ${participant.identity}`);
               this.removeElementByID(participant.identity)
             
            });
          },
          async switchCamera(room) {
              this.isCameraSwitched = true
              const cameraTrack = this.ConnectTrack.find(track => track.kind === 'video');
              this.currentFacingMode = this.currentFacingMode === "user" ? "environment" : "user"
              cameraTrack.restart({ facingMode: this.currentFacingMode });
              this.isCameraSwitched = false
          },
          turnVideoOff() {
              const room = this.activeRoom.__v_raw || this.activeRoom;
              room.localParticipant.videoTracks.forEach(publication => {
                  publication.track.disable();
              });
              this.videoBtnFlag = false;
          },
          turnVideoOn(){
            const room = this.activeRoom.__v_raw || this.activeRoom;
              room.localParticipant.videoTracks.forEach(publication => {
                  publication.track.enable();
              });
              this.videoBtnFlag = true;
          },
          turnAudioOff() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
              room.localParticipant.audioTracks.forEach(publication => {
                  publication.track.disable();
              });
              this.audioBtnFlag = false;
  
          },
          turnAudioOn() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
              room.localParticipant.audioTracks.forEach(publication => {
                  publication.track.enable();
              });
              this.audioBtnFlag = true;
          },
          endTwilioVideoCall() {
            this.exitVideoCallLogEntry('DOCTOR', this.bookingID)
            this.closeTheRoom();
          },
          refreshPage() {
              this.$router.go();
          },
          joinVideoCallLogEntry(userType, booking_id) {
            axios_auth_instance_doctor.post(`/joinCallEntryCPI`, {
              userType: userType,
              typeOfUser: userType,
              bookingId: booking_id,
              token: this.currentUser
            });
          },
          exitVideoCallLogEntry(userType, booking_id) {
            axios_auth_instance_doctor.post(`/exitCallLogEntryCPI`, {
              userType: userType,
              typeOfUser: userType,
              bookingId: booking_id,
              token: this.currentUser
            });
          },
          closeTheRoom() {
            const room = this.activeRoom.__v_raw || this.activeRoom;
            room.localParticipant.tracks.forEach((publication) => {
              const track = publication.track;
              if (track) {
                  track.stop(); // This stops the camera & mic
                  console.log(`Stopped track: ${track.kind}`);
              }
            });
              room.disconnect();
              this.callDisconnected = true;
              this.activeRoom = null;
          },
          addTreatmentInfo () {
            this.prescriptionDetails.treatment.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
          },
          removeTreatmentInfo (medicine,dosage,frequency,duration) {
            this.prescriptionDetails.treatment.forEach(function (item, index, object) {
              if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration)
              {
                object.splice(index, 1);
              }
            });
          },
          async getSpecializationList() {
            var data = {
              token : this.currentUser,
              typeOfUser : "DOCTOR",
              bookingId: this.bookingID,
              page: 1,
              limit: 8
            };
  
            await axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", data)
            .then((readAllSpecializationsResponse) => {
              var spec_list = readAllSpecializationsResponse.data.data;
              this.specialization_list.push({'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"});
              this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
              spec_list.forEach((x) =>{
                this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
              });
                        let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
              if(obj)
              {
                this.selected_specialization = obj;
              }
              else if(successResponse.data.appointment_prescription_data.length > 0)
              {
                this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
                this.others = presc_dets.referredSpecialistName;
              }
            })
            .catch((readAllSpecializationsError) =>{
              console.log('ERROR: ', readAllSpecializationsError);
            });
          },
          async savePrescriptionAndExit() {
            this.prescription_fill_flag = false;
  
            let data = {
              token: this.currentUser,
              typeOfUser: "DOCTOR",
              appointmentID: this.bookingID,
              allergies: this.prescriptionDetails.knownDrugAllergies,
              diagnosedWith: this.prescriptionDetails.diagnosis,
              currentMedication: this.prescriptionDetails.regularAndCurrentMedicines,
              prescription: this.prescriptionDetails.treatment,
              presentSymptoms: this.prescriptionDetails.presentingSymptoms,
              medicalHistory: this.prescriptionDetails.pastMedicalHistory,
              surgicalHistory: this.prescriptionDetails.pastSurgicalHistory,
              familyHistory: this.prescriptionDetails.familyHistory,
              vaccinationHistory: this.prescriptionDetails.vaccinationHistory,
              investigationNeeded: this.prescriptionDetails.investigationsNeeded,
              comeAfter: this.prescriptionDetails.toComeAfter,
              referredSpecialistName: this.prescriptionDetails.referTo.specialization_key,
              // covidRelatedSymptoms: { type: 'array', required: false, allowEmpty: true },
              suggestion: this.prescriptionDetails.suggestions,
              // examinationTBD: { type: 'string', required: false, allowEmpty: true },
              // followUp: { type: 'boolean', required: false, allowEmpty: true },
              // patientNoShow: { type: 'boolean', required: false, allowEmpty: true },
              // referredSpecialist: { type: 'boolean', required: false, allowEmpty: true },
              // unstableConnection: { type: 'boolean', required: false, allowEmpty: true },
              // appointmentDate: { type: 'string', required: false, allowEmpty: true },
              // followUpDate: { type: 'string', required: false, allowEmpty: true },
              // break_here_for_newtemplate_variable: { type: 'string', required: false, allowEmpty: true }
            }
  
            try {
              let response = await axios_auth_instance_doctor.post('/createPrescriptionCPI', data);
              console.log('Response: ', response);
              alert('Prescription Saved!');
              this.$router.go(-1);
  
              // this.prescription_fill_flag = true;
            } catch (error) {
              console.log('Error Saving Presciption Details: ', error);
              alert('Error Saving Presciption Details');
              this.prescription_fill_flag = true;
            }
          },
          async getCPIAppointmentPrescriptions() {
            this.prescription_fill_flag = false;
            var data = {
              token : this.currentUser,
              typeOfUser : "DOCTOR",
              bookingId: this.bookingID
            };
  
            try {
              let response = await axios_auth_instance_doctor.post('/getCPIAppointmentPrescriptions', data);
              console.log('Got CPI Appointment Prescriptions: ', response);
              this.appointmentDetails = response.data;
              this.prescriptionDetails = {
                ...this.prescriptionDetails,
                ...this.appointmentDetails.current_prescription
              }
              this.prescriptionHistory = this.appointmentDetails.prescription_history;
              // console.log(this.appointmentDetails.patient_data);
              this.prescription_fill_flag = true;
            } catch (error) {
              console.log('Error Fetching Appointment Presciption Details: ', error);
              alert('Error Fetching Appointment Presciption Details');
              this.prescription_fill_flag = true;
            }
          },
          openPrescription(prescription) {
            console.log('prescription: ', prescription);
  
            this.viewPrescriptionObject = {
              presentingSymptoms: prescription.presentSymptoms,
              pastMedicalHistory: prescription.medicalHistory,
              pastSurgicalHistory: prescription.surgicalHistory,
              familyHistory: prescription.familyHistory,
              regularAndCurrentMedicines: prescription.current_taking_medicines,
              knownDrugAllergies: prescription.allergies,
              vaccinationHistory: prescription.vaccinationHistory,
              diagnosis: prescription.diagnosed_with,
              treatment: prescription.prescription,
              suggestions: prescription.suggestion,
              investigationsNeeded: prescription.investigationNeeded,
              toComeAfter: prescription.comeAfter,
              referTo: prescription.referredSpecialistName,
            };
  
            this.viewPrescription = true;
          },
          closeViewPrescription() {
            this.viewPrescription = false;
          }
      },
      
      beforeDestroy() { // Vue 3
        this.closeTheRoom();
    }
  };
  </script>
  
  <style scoped>
  .logoClass {
    @media (max-width: 768px){
      width: 80px !important;
    }
  }
  
  .pageBody {
    display: flex;
  }
  
  #remote-media-div-remote>>>video {
      border-radius: 20px !important;
      width: 350px !important;
  }
  
  #remote-media-div-local {
      position: absolute;
      /* bottom: 150px; */
      right: 10px;
      top: 550px;
  }
  
  #remote-media-div-local>>>video {
      border-radius: 8px;
      width: 150px;
  }
  
  .video-container {
    display: flex;
    flex-wrap: wrap;
  }
  .remote_video_container {
      background: red !important;
      position: relative;
  }
  
  .fullLayout {
      display: flex; flex-direction: column; justify-content: center;
  }
  .home {
    width: 100%;
    height: 850px;
    background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
    background-color: rebeccapurple !important;
  }
  .gradientBG {
    background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
  }
  .layout{
      /* position: sticky;
      position: -webkit-sticky;  */
      top: 0em;
      width: 100%;
      height: 60px;
  }
  .routerView{
      height: calc(100vh - 60px) !important;
      overflow-y: auto !important;
      overflow-x: hidden;
  }
  .body{
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      
      margin-top: 70px;
  }
  .gradientBackground {
      background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
  }
  .remoteParticipant {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    @media  (max-width: 1023px) {
      flex-direction: column;
    }
  }
  .callControlsSegment {
    /* background-color: rebeccapurple !important; */
  }
  .prescriptionHeadings {
    font-size: large; font-weight: 500;
  }
  .prescriptionDetails {
    margin-right: 10px;
    border-radius: 8px !important;
  }
  .addRemoveButton {
    cursor: pointer;
  }
  .activeClass {
      border-radius: 8px !important;
      color: white !important;
      /* background-color: #48ACEF !important; */
  }
  </style>
  